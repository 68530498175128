import { getCommonParamsItemLabel } from '@/utils/common.js'

export const contentTableConfig = {
  title: '',
  propList: [
    {
      prop: 'title',
      label: 'profile.title'
    },
    {
      prop: 'articleType',
      label: 'profile.article-type',
      formatter: (row) => {
        return getCommonParamsItemLabel(row, 'article_type')
      }
    },
    {
      prop: 'langType',
      label: 'profile.lang-type',
      formatter: (row) => {
        return getCommonParamsItemLabel(row, 'lang_type')
      }
    },
    // {
    //   prop: 'content',
    //   label: 'profile.content',
    //   width: 250
    // },

    // {
    //   prop: 'remark',
    //   label: 'general.remark',
    //   width: 200
    // },
    {
      prop: 'status',
      label: 'general.status',
      formatter: (row) => {
        return getCommonParamsItemLabel(row, 'article_status')
      }
    },
    {
      prop: 'createdTime',
      slotName: 'formatZeroTime',
      label: 'common.app-creation-time'
    },
    {
      label: 'common.app-operation',
      width: '400',
      slotName: 'handler'
    }
  ],
  showIndexColumn: true
}
