<template>
  <div class="applications">
    <page-search
      ref="pageSearchRef"
      :searchFormConfig="searchFormConfig"
      :pageTitle="$t('general.router-article-center')"
      @resetBtnClick="handleResetClick"
      @queryBtnClick="handleQueryClick"
    >
    </page-search>
    <page-content
      style="margin-top: 10px"
      ref="pageContentRef"
      v-bind="tableOptionsConfig"
      @editBtnClick="handleEditData"
      @viewBtnClick="handleViewData"
      @newBtnClick="handleNewData"
      @pageInfoChange="pageInfoChangeAction"
    >
      <template #otherHandler="scope">
        <el-button
          v-if="isSwitch && isNormalStatus(scope.row.status)"
          class="opt-btn"
          icon="el-icon-lock"
          size="mini"
          plain
          type="warning"
          @click="handleEnableClick(scope.row, 'disable')"
          >{{ $t('general.release') }}</el-button
        >
        <el-button
          v-if="isSwitch && !isNormalStatus(scope.row.status)"
          class="opt-btn"
          icon="el-icon-unlock"
          size="mini"
          plain
          @click="handleEnableClick(scope.row, 'enable')"
          >{{ $t('general.prerelease') }}</el-button
        >
      </template>
    </page-content>
  </div>
</template>

<script setup>
import { ref, computed, getCurrentInstance } from 'vue'

import pageSearch from '@/components/PageSearch/page-search'
import pageContent from '@/components/PageContent/page-content.vue'

import { searchFormConfig } from './config/search'
import { contentTableConfig } from './config/table'

import { useRouter } from 'vue-router'
import { usePermission } from '@/hooks/use-permission'

// 表格基础配置项
const tableOptions = {
  moduleName: 'system', // 所属的模块
  pageName: 'article', // 所属的页面
  contentTableConfig, // 表格列的基础配置
  pageAction: 'system/getArticleList', // 获取表格的Action
  deleteRecordAction: 'system/deleteArticleById', // 删除record的Action
  pageCountGetter: 'system/pageListCount', // 获取表格记录数据总和getters
  pageListGetter: 'system/pageListData' // 获取表格列表数据getters,
}

const pageSearchRef = ref('')

const tableOptionsConfig = computed(() => tableOptions)

const pageContentRef = ref('')
const { appContext } = getCurrentInstance()
const handleMessageCommit =
  appContext.config.globalProperties.$handleMessageCommit

const handleQueryClick = (queryInfo) => {
  // 调用page-search中的getPageData方法
  pageContentRef.value.getPageData(queryInfo)
}
const handleResetClick = () => {
  pageContentRef.value.getPageData()
}
const router = useRouter()
// 获取页面操作的权限
const isSwitch = usePermission(
  tableOptions.moduleName,
  tableOptions.pageName,
  'switch'
)

const isNormalStatus = (status) => {
  return status === '00'
}

const handleEnableClick = async (row, type) => {
  const url = type === 'enable' ? 'handleEnableArticle' : 'handleDisableArticle'
  handleMessageCommit(`system/${url}`, row.id).then(() => {
    pageContentRef.value.getPageData()
  })
}

const handleViewData = (id) => {
  router.push(`/system/article/view/${id}`)
}

const handleEditData = (id) => {
  router.push(`/system/article/edit/${id}`)
}

const handleNewData = (id) => {
  router.push(`/system/article/new`)
}

const pageInfoChangeAction = () => {
  const formData = pageSearchRef.value.getCurrentFieldValues()
  pageContentRef.value.getPageData(formData)
}
</script>
