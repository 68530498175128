import { getCommonParamsOptions } from '@/utils/common.js'
export const searchFormConfig = {
  // colLayout: {
  //   span: 8
  // },
  formItems: [
    {
      field: 'title',
      type: 'input',
      label: 'profile.title'
    },
    {
      field: 'articleType',
      type: 'select',
      label: 'profile.article-type',
      isResolveGlobalParams: true,
      options: [],
      handler: () => getCommonParamsOptions('article_type')
    },
    {
      field: 'langType',
      type: 'select',
      label: 'profile.lang-type',
      isResolveGlobalParams: true,
      options: [],
      handler: () => getCommonParamsOptions('lang_type')
    },
    {
      field: 'status',
      type: 'select',
      label: 'common.app-status',
      isResolveGlobalParams: true,
      options: [],
      handler: () => getCommonParamsOptions('article_status')
    },
    {
      field: 'createTime',
      type: 'datepicker',
      label: 'general.creation-date',
      otherOptions: {
        type: 'daterange'
      },
      dateFieldName: ['startDateTime', 'endDateTime']
    }
  ]
}
